// Not found page

.bm-item-list {
  margin-top: 0;
}

.menu-hover {
 padding: 0 1em;
}

.menu-hover:hover {
  opacity: 0.5;
}

.hamburger-ul {
  display: block;
  outline: none;

  li a h3 {
    border: 0;
    border-top: dotted 1px rgba(160, 160, 160, 0.3);
    margin: 1.5em 0 0 0;
    padding: 1.5em 0 0 0;
  }

  li a h3:hover {
    color: _palette(accent);
  }

  li {
    display: block !important;
  }

  h3 {
    font-size: 0.7em;
  }

  .index-li {
    border-top: none;
  }
}
